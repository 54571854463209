import greenLocation2 from '@/assets/img/green_car.png'
import greenLocation from '@/assets/img/location-green.png'
import redLocation from '@/assets/img/location-red.png'
import yellowLocation from '@/assets/img/location-yellow.png'
import greenLocation3 from '@/assets/img/red_car.png'
import tsoodd from '@/assets/img/rhombus.png'
import { useGetDetailUnitMutation } from '@/modules/maps/services/homeMapService'
import { DetailUnit, UnitsMapType } from '@/modules/maps/types/unitsMapType'
import {
  balloonGreen,
  balloonRed,
  balloonYellow,
} from '@/modules/maps/utils/balloons'
import { useMediaQuery } from '@mui/material'
import { Placemark } from '@pbe/react-yandex-maps'
import React, { FC, useState } from 'react'

interface Props {
  unit: UnitsMapType
  yamap: any
}

const PlacemarkBalloon: FC<Props> = ({ unit, yamap }) => {
  const isMobile = useMediaQuery('(max-width:767px)')

  const [getDetailUnit, { isLoading, isError, error }] =
    useGetDetailUnitMutation()
  const [unitData, setUnitData] = useState<DetailUnit>({
    id: 0,
    name: '',
    address: '',
    connectionStatus: 3,
    countModules: 0,
    signal: 0,
    smart: false,
    is220v: false,
    type: '',
    voltage: 0,
    typeId: 0,
    state: 1,
    errorMessage: '',
    ultraShortTermForecastId: 0,
  })

  const onLoadData = async (id: number) => {
    try {
      const res = await getDetailUnit(id).unwrap()
      if (Object.values(res).length > 0) {
        setUnitData(() => res)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getIconImageHref = (unit: UnitsMapType) => {
    if (
      unit.smart ||
      unit.typeId === 7 ||
      unit.typeId === 8 ||
      unit.typeId === 9
    ) {
      if (unit.state === 1) {
        if (
          unit.typeId === 4 &&
          (unit.ultraShortTermForecastId === 2 ||
            unit.ultraShortTermForecastId === 4)
        ) {
          return greenLocation2
        } else if (
          unit.typeId === 4 &&
          (unit.ultraShortTermForecastId === 3 ||
            unit.ultraShortTermForecastId === 5)
        ) {
          return greenLocation3
        }
        return greenLocation
      }
      return unit.state === 2 ? yellowLocation : redLocation
    }
    return tsoodd
  }

  return (
    <Placemark
      defaultGeometry={unit.coordinates}
      onClick={() => {
        yamap.current.setCenter(
          unit.coordinates,
          yamap.current._zoom > 10 ? yamap.current._zoom : 10,
          {
            checkZoomRange: true,
            duration: 500,
          }
        )
        onLoadData(unit.id)
      }}
      options={{
        iconLayout: 'default#image',
        iconImageHref: getIconImageHref(unit),
        iconImageSize: [40, 40],
        openBalloonOnClick: true,
        hideIconOnBalloonOpen: false,
        balloonOffset: [-70, -34],
        balloonMaxWidth: isMobile ? 257 : 400,
      }}
      properties={{
        id: unit.id,
        balloonContentBody:
          unit.state === 1
            ? balloonGreen(isError, isLoading, unit.isGroup ? unit : unitData)
            : unit.state === 2
              ? balloonYellow(isError, isLoading, unitData)
              : balloonRed(isError, isLoading, unitData),
        clusterCaption: `
                <div style='display: flex; width: 100px; justify-content: space-between; align-items: center; gap: 10px'>
                  <div>${unit.name}</div>
                  <div style='background-color: ${
                    !unit.smart
                      ? '#7d8eff'
                      : unit.status === 1
                        ? 'green'
                        : unit.status === 2
                          ? 'yellow'
                          : unit.status === 3
                            ? 'red'
                            : 'black'
                  }; width: 10px; height: 10px; transform: ${!unit.smart ? 'rotate(45deg)' : 'unset'}; border-radius: ${unit.smart ? '50%' : '0'};'></div>
                </div>`,
        clusterHideIconOnBalloonOpen: false,
        geoObjectHideIconOnBalloonOpen: false,
      }}
    />
  )
}

export default PlacemarkBalloon
